:root {
    --main-bg: #240140;
    --white: #fff;
    --black: #000;
    --red: #D8375B;
    --gray: #C4C4C4;
}

.main_btn {
    height: 40px;
    padding: 0 1em;
    font-size: 18px;
    color: var(--white);
    background: linear-gradient(45deg, #8b42ae 0%, #e12f47 53.08%, #e67011 100%);
    background-origin: border-box;
    border: 1px solid transparent;
    border-radius: 20px;
    text-transform: uppercase;
    box-shadow: inset 0 40px var(--main-bg);
}
.main_btn:hover, .main_btn.selected {
    box-shadow: none;
}
