.main {
    overflow: hidden;
    background-color: var(--main-bg);
}

.content {
    max-width: 1114px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
}
.content_title {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2.35em;
    font-size: 56px;
}
.content_title h2 {
    line-height: 1.2em;
    font-weight: 700;
    color: var(--white);
    text-transform: uppercase;
}
.content_title h2 p {
    margin-top: 9px;
    background: linear-gradient(45deg, #8b42ae 0%, #e12f47 53.08%, #e67011 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}
.content_blink {
    position: absolute;
    right: 3%;
    bottom: 12%;
    max-width: 164px;
    width: 18vmin;
    max-height: 164px;
    height: 18vmin;
    margin-right: 5%;
}
.content_blink:after {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    background-color: #b8008e;
    filter: blur(75px);
    border-radius: 50%;
}
.content_blink:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    display: block;
    max-width: 101px;
    width: 12vmin;
    max-height: 101px;
    height: 12vmin;
    background: linear-gradient(183.92deg, rgba(254, 111, 147, 0.03) 4.35%, rgba(254, 111, 147, 0.9) 91.26%);
    box-shadow: inset 12.075px 12.075px 50.715px rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    transform: rotate(-31deg);
    z-index: 9;
}
.modules_wrap {
    margin-bottom: 175px;
}
.module {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.module + .module {
    margin-top: 120px;
}
.module:nth-child(odd) .module_text {
    order: 1;
    margin-right: 16px;
}
.module:nth-child(even) .module_text {
    order: 3;
    margin-left: 16px;
}
.module_with_blink {
    position: relative;
}
.module_with_blink .module_blink {
    position: absolute;
    right: 0;
    bottom: 0;
    max-width: 432px;
    width: 32vmin;
    max-height: 432px;
    height: 32vmin;
    background: linear-gradient(183.92deg, rgba(254, 111, 147, 0.03) 4.35%, rgba(254, 111, 147, 0.9) 91.26%);
    box-shadow: inset 19.2116px 19.2116px 80.6887px rgba(255, 255, 255, 0.5);
    transform: rotate(149.01deg);
    border-radius: 50%;
}
.module_with_blink .module_blink:before {
    content: "";
    position: absolute;
    top: -22%;
    left: 26%;
    max-width: 164px;
    width: 18vmin;
    max-height: 164px;
    height: 18vmin;
    background: #b8008e;
    filter: blur(75px);
    border-radius: 50%;
}
.module_img {
    order: 2;
    max-width: 632px;
    width: calc(70% - 16px);
    max-height: 340px;
    height: 37vmin;
    background: linear-gradient(45deg, #8b42ae 0%, #e12f47 53.08%, #e67011 100%);
    background-origin: border-box;
    border: 2px solid transparent;
    border-radius: 12px;
    text-transform: uppercase;
    box-shadow: inset 0 340px var(--gray);
    overflow: hidden;
}
.module_img video {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.module_text:not(.module_text_no_image) {
    max-width: 300px;
    width: 30%;
}
.module_text h4 {
    margin-bottom: 25px;
    font-size: 28px;
    line-height: 1.2em;
    font-weight: 700;
    color: var(--red);
}
.module_text h3 {
    font-size: 36px;
    line-height: 1.2em;
    font-weight: 700;
    color: var(--white);
}
.module_text_no_image {
    max-width: 632px;
}
.module_text_no_image p {
    margin-top: 2.83em;
    font-size: 18px;
    line-height: 1.2em;
    color: var(--gray);
}
.social_module {
    display: flex;
    justify-content: space-between;
    gap: 20px 16px;
}
.social_module + .social_module {
    margin-top: 146px;
}
.social_module.discord, .social_module.youtube {
    align-items: center;
}
.social_module.twitter .social_module_img {
    margin-top: 6%;
    order: 2;
}
.social_module_img {
    max-width: 189px;
    width: 30%;
}
.social_module_text_wrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 20px 16px;
    width: 70%;
}
.youtube .social_module_text {
    max-width: 742px;
    width: 70%;
}
.social_module_text h4 {
    margin-bottom: 30px;
    font-size: 18px;
    line-height: 1.2em;
    font-weight: 700;
    color: var(--white);
}
.social_module_text p {
    font-size: 18px;
    line-height: 1.2em;
    color: var(--white);
}
.social_module_text__link  h4 {
    color: var(--white);
}
.moduleTitle {
    text-transform: uppercase;
}

.social_module_text__link{
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 1280px) {
    .content_title {
        font-size: calc(28px + (56 - 28) * ((100vw - 375px) / (1280 - 375)));
   }
    .modules_wrap {
        margin-bottom: 16vmin;
   }
    .module + .module {
        margin-top: 12vmin;
   }
    .module_text h4 {
        margin-bottom: 0.8em;
        font-size: calc(18px + (28 - 18) * ((100vw - 375px) / (1280 - 375)));
   }
    .module_text h3 {
        font-size: calc(24px + (36 - 24) * ((100vw - 375px) / (1280 - 375)));
   }
    .module_text_no_image p {
        font-size: 16px;
   }
    .social_module_img {
        width: 18%;
   }
    .social_module_text_wrap {
        flex-wrap: wrap;
        width: 82%;
   }
    .youtube .social_module_text_wrap, .discord .social_module_text_wrap {
        justify-content: flex-start;
   }
    .social_module_text h4 {
        margin-bottom: 1.5em;
        font-size: 16px;
   }
    .social_module_text p {
        font-size: 16px;
   }
    .social_module + .social_module {
        margin-top: 10vmin;
   }
   .social_module {
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
}
}
@media screen and (max-width: 992px) {
    .twitter .social_module_text_wrap {
        justify-content: flex-start;
   }
}
@media screen and (max-width: 768px) {
    .module {
        flex-direction: column;
        align-items: flex-start;
   }
    .module + .module {
        margin-top: 12vmin;
   }
    .module:nth-child(odd) .module_text {
        order: 1;
        margin-right: 0;
   }
    .module:nth-child(even) .module_text {
        order: 1;
        margin-left: 0;
   }
    .social_module {
        flex-wrap: wrap;
   }
    .social_module.twitter .social_module_img {
        margin-top: 0;
        order: 0;
   }
    .social_module_img {
        width: 100%;
   }
    .social_module_text_wrap {
        width: 100%;
        justify-content: center !important;
   }
   .social_module_text{
       max-width:100% !important;
       width: 100% !important;
   }
    .module_text:not(.module_text_no_image) {
        max-width: 100%;
        width: 100%;
        margin-right: 0;
   }
    .module_text:not(.module_text_no_image) h3 {
        margin-bottom: 1em;
   }
    .module_img {
        max-width: 100%;
        width: 100%;
        height: 44vmin;
   }
   .modules_wrap {
    margin-bottom: 0;
    }
}