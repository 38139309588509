.countdown{
  width: 100%;
  height: 150px;
  position: relative;
}
.countdown_bg{
  padding: 0 10vw;
  width: 100%;
  height: 100%;
  background-color:#6B0152; 
}

.coutdown_rotate>span,
.coutdown_rotate {
  font-family: 'Noir Pro bold';
  font-size: 5vw;
  color: black;
  -webkit-text-fill-color: #6B0152;
  -webkit-text-stroke-width: 3px;
  -webkit-text-stroke-color: white;
  }

.coutdown_rotate {
  width: 100%;
  height: 150px;
  position: absolute;
  top: 0;
  transform: matrix(1, -0.02, 0, 1, 0, 0);
  background: rgba(235, 232, 228, 0.5);
  backdrop-filter: blur(18px);
  transform: rotate(-0.91deg);

  
}
.countdown_content{
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transform: rotate(0.91deg);
}
.countdown_text_time{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.countdown_text_time > span{
  color: black;
  -webkit-text-fill-color: white;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
  min-width: 100px;
  user-select: none;
}
.countdown_head{
  font: 55px/67px 'Noir Pro bold';
  color: rgba(235, 232, 228, 0.5);;
  -webkit-text-fill-color: #A6779A;
  -webkit-text-stroke-width: 1px;
  text-shadow: 2px 0 0 #fff, -2px 0 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff, 1px 1px #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff;
}
@media screen and (max-width:992px) {
  .countdown{
    height: 110px;
  }
  .coutdown_rotate{
    height: 110px;
  }
  .countdown_head{
    font: 42px/50px 'Noir Pro bold';
  }
  .countdown_content{
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  .countdown_text_time > span{
    text-align: center;
  }
}