.first_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color:  #2F183D ;
  background-image: url("../../assets/photo/first_section_bg.png");
  background-size: cover;   
  height: 800px; 
}
.title_section{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.title{
  color: #FFFFFF;
  font: 56px/67px 'Noir Pro bold';
}
.join_us_btn{
  width: 189px;
  text-align: center;
  height: 60px;
  color: #FFFFFF;
  background-color: #D8375B;
  border: none;
  border-radius: 30px;
  font: 600 18px/22px 'Noir Pro semiBold';
  line-height: 60px;
  text-decoration: none;
}
.title span{
  background-image: linear-gradient(45deg, #8B42AE 0%, #E12F47 53.08%, #E67011 100%);
  background-size: 100%; 
  background-clip: text;   
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-text-fill-color: transparent;
}
.squir_image{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
}
.squir_image img{
  width: 100%;
}
/* .first_section .squir_image  {
  position: relative;
} */

/* .first_section .squir_image img{
  width: 100%;
}

.first_section .title {
  left: 15%;
  position: relative;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  width: 60%;
}
.title .elipse{
  width: 10vw;
  height: 10vw;
  background-color: rgba(184,0,142,0.20);
  position: absolute;
  border: 10px solid transparent;
  border-radius: 50%;
  -webkit-box-shadow: 0px 0px 49px 50px rgba(184,0,142,0.20); 
  box-shadow: 0px 0px 49px 50px rgba(184,0,142,0.20);
  left: -10%;
  top: -50%;
}
.elipse_1 {
  width: 30vw;
  height: 30vw;
  background-color: rgba(184,0,142,0.20);
  border: 10px solid transparent;
  border-radius: 50%;
  -webkit-box-shadow: 0px 0px 49px 50px rgba(184,0,142,0.50); 
  box-shadow: 0px 0px 79px 70px rgba(184,0,142,0.20);
  position: absolute;
  top: 25%;
  right: 10%;
}

.title h1:first-child {
  color: #ffff;
  font-size: 5vw;
  font-family: 'Noir Pro bold'
}

.title h1:nth-child(2) {
  background-color: #f3ec78;
  background-image: linear-gradient(45deg, #8B42AE 0%, #E12F47 53.08%, #E67011 100%);
  background-size: 100%; 
  background-clip: text;   
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-text-fill-color: transparent;
  font-family: 'Noir Pro bold';
  font-size: 5vw
} */


@media screen and (max-width: 992px) {
  .squir_image_wrapper{
    position: absolute;
    top: 0;
    right: 0;
  }
  .squir_image{
    width: 70%;
    height: 70%;
    object-fit: cover;
    margin-inline-start: auto;
  }
  .squir_image_banner{
    position: relative;
    z-index: 20;
  }
}

@media screen and (max-width: 778px) {
  .squir_image_wrapper{
    display: none !important;
  }
  .first_section{
    height: 400px;
  }
}