* {
  margin: 0;
  box-sizing: border-box;
}
p{
  margin-bottom: 0;
}
@font-face {
  font-family: 'Noir Pro heavy';
  src: url("./assets/fonts/Noir-pro/NoirPro-Heavy.otf");
}

@font-face {
  font-family: 'Noir Pro bold';
  src: url("./assets/fonts/Noir-pro/NoirPro-Bold.otf");
}

@font-face {
  font-family: 'Noir Pro Light';
  src: url("./assets/fonts/Noir-pro/NoirPro-Light.otf");
}

@font-face {
  font-family: 'Noir Pro medium';
  src: url("./assets/fonts/Noir-pro/NoirPro-Medium.otf");
}

@font-face {
  font-family: 'Noir Pro regular';
  src: url("./assets/fonts/Noir-pro/NoirPro-Regular.otf");
}

@font-face {
  font-family: 'Noir Pro';
  src: url("./assets/fonts/Noir-pro/NoirPro-Regular.otf");
}

@font-face {
  font-family: 'Noir Pro semiBold';
  src: url("./assets/fonts/Noir-pro/NoirPro-SemiBold.otf");
} 
