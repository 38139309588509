.squirrels_images img{
  width: 20%;
  object-fit: cover;
}

.squirrels_images {
  display: flex;
  justify-content: space-between;
  background-color:#2F183D;   
}

@media screen and (max-width: 567px) {
  .squirrels_images{
    flex-wrap: wrap;
    justify-content: center;
    gap: 0;
  } 
  .squirrels_images img{
    width: 50%;
    object-fit: cover;
  }
}