.footer {
  position: relative;
  width: 100%;
  height: 400px;
  background: linear-gradient(135deg, #6b0152 0%, #2f183d 100%);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer > img {
  width: 621px;
  height: 500px;
  margin-block-start: -159px;
}

.footer_brand {
  display: block;
  width: 325px;
  height: 90px;
  flex-shrink: 0;
}

.footer_brand img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.footer h2 {
  height: 34px;
  font-family: "Noir Pro bold";
  font-size: 27px;
  line-height: 34px;
  text-transform: uppercase;
  color: #ffffff;
  margin: 78px 0 0 0;
}

.social {
  display: flex;
  gap: 56px;
  margin: 45px 0 0 0;
}

.social img {
  width: 30px;
  height: 30px;
  object-fit: contain;
}
.footer-right {
  flex: 0 1 325px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.footer-right button {
  font-family: "Noir Pro regular";
  outline: none;
  font-size: 18px;
  width: 189px;
  height: 60px;
  background: #d8375b;
  border-radius: 30px;
  color: #ffffff;
  border: none;
}

@media screen and (max-width: 1366px) {
  .footer > img {
    width: 500px;
    margin-block-start: -49px;
  }
}

@media screen and (max-width: 1200px) {
  footer {
    height: 320px !important;
    overflow: hidden;
  }
  .footer > img {
    width: 410px;
    height: 423px;
    object-fit: contain;
  }

  .footer h2 {
    font-size: 23px;
  }
}

@media (max-width: 992px) {
  .footer > img {
    position: absolute;
    top: 19px;
    left: 0;
    height: 350px;
    object-fit: cover;
  }
  .footer {
    overflow: visible;
  }

  .footer_brand{
    position: relative;
    z-index: 20;
    margin-block-start: auto;
  }

  .footer h2 {
    font-size: 3vw;
  }

  .social img:nth-child(2) {
    margin-right: 5px;
    margin-left: 5px;
  }

  .footer-right button {
    font-size: 13px;
    width: 100px;
    height: 26px;
  }

  .copywrite {
    font-size: 14px;
  }
}


@media screen and (max-width: 567px) {
  footer {
    display: flex;
    flex-direction: column;
    height: auto !important;
    padding: 20px 0;
  }
  .footer > img {
    display: none;
  }
  .social img {
    width: 15px;
    height: 15px;
  }
  .footer-right{
    flex: 0 0 auto;
  }

}
