.chapters_section {
  background-color:#2F183D;   
  display: flex;
  justify-content: space-between;
  padding: 30px 0;
}

.chapters_section .chapters {
  margin-left: 10vw;
  width: 60vw;
}
.count_container h2{
  z-index: 1;
}

.chapter_images{
  display: flex;
  flex-direction: column;
  width: 25%;
}

.chapter_images img{ 
  width: 100%;
  height: 100%;
  max-height: 460px;
  object-fit: cover;
  object-position: center;
}

.count_container{
  position: relative;
}

.count_container img{
  position: absolute;
  top: -60px;
  left: 0;
  width: 120px;
  height: 120px;
  object-fit: contain;
}

.chapters_section .chapters div{
  display: flex;
  align-items: center;
  margin: 3% 0;
}

.count_container{
  position: relative;
  display: block !important;
  width: 80px;
  text-align: center;
}

.chapters_section .chapters div h2{
  font-family: 'Noir Pro bold';
  color: white;
}

.count_container h2 {
  position: relative;
  margin: 0;
  text-indent: 20px;
  font-size: 28px;
  line-height: 1;
}

.chapters_section .chapters div p{
  color: rgba(199, 200, 208, 1);
  width: 65%;
  margin-left: 10%;
}
@media (max-width: 992px) {
  .chapters_section{
      display: flex;
      flex-direction: column;
      padding-bottom: 0;
  }
  .chapter_images{
      flex-direction: row;
      width: 100%;
      overflow: hidden;
  }
  .chapter_images img{
      width: 33.33334%;
  }
  
  .chapters_section .chapters div p{  
      margin-left: 10% 0;
      width: 50%;
  }  

  .chapters_section .chapters {
      margin-left: 0;
      width: 100%;
  }
  .chapters_section .chapters div {
    justify-content: center;
  }
}
