.footer {
    position: relative;
    margin-top: auto;
    padding-top: 160px;
    background-color: #240140;
}
.footer:before {
    content: "";
    position: absolute;
    bottom: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: url('../../assets/footer_img.png') no-repeat left 4% bottom / contain;
    z-index: 9;
}
@media screen and (max-width: 1280px) {
    .footer {
        padding-top: 10vmin;
   }
}
@media screen and (max-width: 768px) {
    .footer {
        padding-top: 0;
   }
}
.footer_img {
    position: absolute;
    bottom: 0;
    max-width: 672px;
    z-index: 1;
}
.footer_inner_wrap {
    background: linear-gradient(135deg, #6b0152 0%, #2f183d 100%);
}
.footer_inner {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1114px;
    width: 100%;
    margin: 0 auto;
    padding: 78px 20px 80px;
    z-index: 9;
}
.footer_inner:before {
    content: "";
    flex: 1 1 0;
}
@media screen and (max-width: 1280px) {
    .footer_inner {
        padding: 5vmin 20px 5vmin;
   }
}
@media screen and (max-width: 768px) {
    .footer_inner {
        flex-direction: column;
        align-items: flex-end;
   }
    .footer_inner:before {
        display: none;
   }
}
.footer_community {
    flex: 1 1 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.footer_community h4 {
    margin-bottom: 1.6em;
    font-size: 28px;
    line-height: 1.2em;
    font-weight: 700;
    color: var(--white);
    text-transform: uppercase;
}
.footer_community .btn_join {
    max-width: 189px;
    height: 60px;
    margin-left: auto;
    padding: 0 2.22em;
    font-size: 18px;
    color: var(--white);
    background-color: var(--red);
    border: none;
    border-radius: 30px;
}
@media screen and (max-width: 768px) {
    .footer_community .btn_join {
        height: 42px;
        font-size: 16px;
   }
}
@media screen and (max-width: 1280px) {
    .footer_community h4 {
        font-size: calc(18px + (28 - 18) * ((100vw - 375px) / (1280 - 375)));
   }
}
.social_community {
    display: flex;
    column-gap: 56px;
    margin-bottom: 40px;
}
@media screen and (max-width: 768px) {
    .social_community {
        column-gap: 32px;
   }
}
.footer_logo {
    cursor: pointer;
}
.footer_logo a img{
    width: 300px;
    height: 120px;
    object-fit: cover;
}
@media screen and (max-width: 1280px) {
    .footer_logo a {
        font-size: calc(28px + (56 - 28) * ((100vw - 375px) / (1280 - 375)));
   }
}
.footer_copyright {
    position: relative;
    width: 100%;
    padding: 9px 20px;
    font-size: 18px;
    line-height: 1.2em;
    color: var(--white);
    background-color: rgba(255, 255, 255, .5);
    text-align: center;
    z-index: 9;
}
@media screen and (max-width: 768px) {
    .footer_copyright {
        font-size: 16px;
   }
}
