.header {
    background-color: var(--main-bg);
    padding-block-end: 153px;

}

@media screen and (max-width: 768px) {
    .header {
        padding: 20px 20px;
   }
}
.header_logo {
    cursor: pointer;
}
.header_logo a {
    font-size: 24px;
    line-height: 1.5em;
    font-weight: 700;
    color: var(--white);
    text-transform: uppercase;
}
.header_language_switch {
    display: flex;
    justify-content: flex-end;
    min-width: 50%;
}
.header_language_switch button {
    max-width: 189px;
    width: calc(50% - 16px);
}
.header_language_switch button + button {
    margin-left: 32px;
}

.container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1114px;
    width: 100%;
    margin: 0 auto;
    padding: 30px 20px;
}

@media screen and (max-width: 768px) {
    .header_language_switch button {
        width: auto;
   }
    .header_language_switch button + button {
        margin-left: 16px;
   }
}

  .header_logo img{
    width: 140px;
    height: 36px;
    object-fit: cover;
  }


@media screen and (max-width: 768px) {
    .container{
        flex-direction: column;
        gap: 20px;
        padding: 0;
    }
}