.header_banner {
    display: flex;
    align-items: center;
    height: 100vh;
    background-color: var(--main-bg);
}
.header_container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1114px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
}
.header_banner_text {
    position: relative;
}
.header_banner_text:before {
    content: "";
    position: absolute;
    top: -12vmin;
    left: -12vmin;
    display: block;
    max-width: 164px;
    width: 18vmin;
    max-height: 164px;
    height: 18vmin;
    background-color: #b8008e;
    filter: blur(125px);
    border-radius: 50%;
}
.header_banner_text:after {
    content: "";
    position: absolute;
    bottom: -10vh;
    left: -60%;
    display: block;
    max-width: 260px;
    width: 28vmin;
    max-height: 260px;
    height: 28vmin;
    background: linear-gradient(183.92deg, rgba(254, 111, 147, 0.03) 4.35%, rgba(254, 111, 147, 0.9) 91.26%);
    box-shadow: inset 19.2116px 19.2116px 80.6887px rgba(255, 255, 255, 0.5);
    transform: rotate(149.01deg);
    border-radius: 50%;
}
.header_banner_text h1 {
    margin-bottom: 1.2em;
    font-size: 56px;
    line-height: 1.2em;
    font-weight: 700;
    color: var(--white);
    text-transform: uppercase;
}
.header_banner_text h1 p {
    background: linear-gradient(45deg, #8b42ae 0%, #e12f47 53.08%, #e67011 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}
.header_banner_subscribe {
    display: flex;
    align-items: flex-start;
}
.header_banner_input {
    max-width: 300px;
    width: 70%;
}
.header_banner_input span {
    display: block;
    padding-left: 1.5em;
    font-size: 18px;
    line-height: 1.2em;
    color: var(--red);
}

.header_banner_input span.loader{
    color: var(--white);
}

.header_banner_input .success{
    color: rgb(25,135,84);
}

.header_banner_wrapper {
    width: 100%;
    height: 60px;
    margin-bottom: 17px;
    padding: 0 1.5em;
    font-size: 18px;
    line-height: 58px;
    color: var(--white);
    background-color: transparent;
    border: 1px solid var(--white);
    border-radius: 30px;
    user-select: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.btn_connect_wallet {
    height: 60px;
    margin-left: 32px;
    padding: 0 1.55em;
    font-size: 18px;
    line-height: 1.2em;
    color: var(--white);
    background-color: var(--red);
    border-radius: 30px;
    border: none;
}
.header_banner_img {
    position: relative;
    max-width: 410px;
    width: 40%;
    min-width: 250px;
    max-height: 402px;
    height: 42vmin;
    min-height: 200px;
    margin-left: 32px;
    background: linear-gradient(45deg, #8b42ae 0%, #e12f47 53.08%, #e67011 100%);
    background-origin: border-box;
    border: 6px solid transparent;
    border-radius: 12px;
    text-transform: uppercase;
    box-shadow: inset 0 420px var(--black);
}
.header_banner_img:before {
    content: "";
    position: absolute;
    top: -6vmin;
    left: -6vmin;
    display: block;
    max-width: 165px;
    width: 12vmin;
    max-height: 165px;
    height: 12vmin;
    background: linear-gradient(183.92deg, rgba(255, 0, 64, 0.03) 4.35%, rgba(255, 0, 202, 0.6) 91.26%);
    border-radius: 50%;
    box-shadow: inset 12.075px 12.075px 50.715px rgba(255, 255, 255, 0.5);
    transform: rotate(31deg);
}
.header_banner_img:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--black);
    border-radius: 6px;
}
.header_banner_img img {
    position: relative;
    display: block;
    height: 100%;
    margin: 0 auto;
    object-fit: contain;
    z-index: 3;
}
.header_banner_img .header_banner_circle_bg {
    position: absolute;
    right: -12vmin;
    bottom: -5vmin;
    max-width: 164px;
    width: 18vmin;
    max-height: 164px;
    height: 18vmin;
}
.header_banner_img .header_banner_circle_bg:before {
    content: "";
    position: absolute;
    top: 20px;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: #b8008e;
    filter: blur(125px);
    border-radius: 50%;
}
.header_banner_img .header_banner_circle_bg:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    display: block;
    max-width: 101px;
    width: 12vmin;
    max-height: 101px;
    height: 12vmin;
    background: linear-gradient(183.92deg, rgba(254, 111, 147, 0.03) 4.35%, rgba(254, 111, 147, 0.9) 91.26%);
    box-shadow: inset 12.075px 12.075px 50.715px rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    transform: rotate(31deg);
    z-index: 9;
}

@media screen and (max-width: 1280px) {
    .header_banner {
        margin: 0 auto 20vmin;
   }
    .header_banner_img {
        height: 38vmin;
   }
    .header_banner_text h1 {
        font-size: calc(28px + (56 - 28) * ((100vw - 375px) / (1280 - 375)));
   }
}
@media screen and (max-width: 768px) {
    .header_container{
        flex-direction: column;
        justify-content: center;
    }
    .header_banner {
        flex-wrap: wrap;
        margin: 0;
   }
    .header_banner_text {
        width: 100%;
   }
    .header_banner_input {
        max-width: initial;
   }
    .header_banner_wrapper {
        height: 42px;
        line-height: 40px;
        font-size: 16px;
        margin-block-end: 32px;
   }
    .header_banner_input span {
        font-size: 16px;
   }
    .btn_connect_wallet {
        height: 42px;
        margin-left: 16px;
        font-size: 16px;
   }
    .header_banner_img {
        width: auto;
        max-height: 230px;
        margin: 0 auto;
   }
}
