.video{
  width: 100%;
  height: 800px;
  object-fit: cover;
}

.video-container {
  position: relative;
  width: 100%;
  height: 800px;
}
.embed-responsive-item{
  width: 100%;
  height: 800px;
}
.video-container img {
  position: absolute;
  top: calc(50% - 118px / 2);
  left: calc(50% - 101px / 2);
  cursor: pointer;
}

@media (max-width: 576px) {
  .video-container {
    height: 400px;
  }
  .video{
    height: 400px;
  }
}