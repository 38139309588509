
.m-0{
  margin: 0;
}
.navbar{
  position: absolute;
  z-index: 100;
  height: 100px;
  width: 100%;
}
.nav .logo img{
  width: 140px;
  height: 36px;
  object-fit: cover;
}

.nav_links{
  color: #FFFFFF;
  margin: 0 5px;
  font: 600 18px/21px 'Poppins', sans-serif;
  text-decoration: none;
  margin-right: 58px;
}
.nav_links:hover{
  color: #FFFFFF;
}
.soc_lings_images{
  height: 24px;
  margin-left: 45px;
}

.connect_wallet_btn{
  font: 18px/21px 'Noir Pro semiBold';
  font-style: normal;
  color:  #ffffff;
  border: 1px solid #D8375B;
  border-radius: 30px;
  background-color: transparent;
  padding: 19px 28px;
}

@media screen and (max-width: 770px) {
  .header__socials .navbar-nav{
    justify-content: flex-end !important; 
  }
}

@media screen and (max-width: 567px) {
  .navbar-expand .navbar-nav{
    display: none;
  }
}