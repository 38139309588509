.about_section {
  display: flex;
  justify-content: space-between;
  background-color:#2F183D;   
  padding: 125px 0 145px 0;
  background-image: url("../../assets/photo/aboutus_bg.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}


.aboutus{
  font-size: 18px;
  color: #C7C8D0;
}


.about_image{
  height: 100%;
  display: flex;
  align-items: center;
}
.squirrel_img1{
  width: 100%;
}
.title{
  color: #FFFFFF;
  font: 56px/67px 'Noir Pro bold';
}
@media screen and (max-width:992px) {
  .about_section{
    padding: 35px 0 45px 0;
  }
  .title{
    font: 42px/50px 'Noir Pro bold';
  }
  .aboutus{
    font-size: 14px;
  }
  .about_image{
    align-items: flex-start;
  }
}

@media screen and (max-width: 567px) {
  .about_image, .about_image img{
    width: 100%;
  }
}